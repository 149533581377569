import type React from 'react'
import {useCallback, useRef} from 'react'

import {useDragAndDrop} from '../../hooks/use-drag-and-drop'
import {useSortableItem} from '../../hooks/use-sortable-item'

export interface MoveDialogTriggerProps<T> {
  /**
   * The clickable component that will trigger the move dialog to open
   */
  Component: T
  /**
   * Optional: The ref to return focus to after the move dialog is closed
   */
  returnFocusRef?: React.RefObject<HTMLElement>
}

/**
 * A trigger component that opens the move dialog when clicked.
 *
 * @param props MoveDialogTriggerProps
 */
export const MoveDialogTrigger = <T extends React.ElementType>({
  Component,
  returnFocusRef: externalReturnFocusRef,
  ...props
}: MoveDialogTriggerProps<T> & React.ComponentProps<T>) => {
  const {title, index} = useSortableItem()
  const ref = useRef<HTMLElement | null>(null)
  const {items, openMoveDialog} = useDragAndDrop()

  const openDialog = useCallback(() => {
    const returnFocusRef = externalReturnFocusRef ? (externalReturnFocusRef as React.RefObject<HTMLElement>) : ref
    openMoveDialog(title, index, returnFocusRef)
  }, [openMoveDialog, title, index, externalReturnFocusRef, ref])

  if (items.length === 1) return null

  return <Component ref={ref} {...props} onClick={openDialog} />
}

try{ MoveDialogTrigger.displayName ||= 'MoveDialogTrigger' } catch {}